:root {
  --primary-brick-color: #b44;
  --primary-gold-color: #DFDCA1;
  --primary-tan-color: #ddd8d2;
  --primary-gray-color: #232c33;

  --secondary-brick-color: #963636;
  --secondary-tan-color: #c8c0b6;

  --tertiary-brick-color: #5e2222;
  --tertiary-tan-color: #9c8e7c;

  --primary-background-color: #2B3840;
  --secondary-background-color: #3e4950;
  --tertiary-background-color: #383d4d;

  --text-gray-color: rgba(255, 255, 255, 0.55);

  --footer-color: #212529;

  --currency-color: #f5c116;

  --junk-rarity-color: #999999;
  --common-rarity-color: #33cc33;
  --uncommmon-rarity-color: #0099ff;
  --rare-rarity-color: #cc33ff;
  --epic-rarity-color: #ffcc00;
  --legendary-rarity-color: #ff6600;
}

.app {
  overflow-x: hidden;
  font-family: Oldenburg;
  height: 100%;
}

a {
  color: var(--primary-gold-color)
}

a:link {
  text-decoration: none;
}

.accordion-button, .accordion-item {
  color: white;
  background-color: var(--primary-background-color);
  border-color: var(--primary-gray-color);
}

.accordion-button:focus {
  box-shadow: 0 0 5px .25rem rgb(150 , 54, 54, .25);
}

.accordion-button:not(.collapsed) {
  background-color: var(--primary-background-color);
  color: white;
}

.accordion-body {
  background-color: var(--secondary-background-color);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-background-color);
  max-width: 100%;
  height: 100%;
}

.bg-junk {
  background-color: var(--junk-rarity-color);
}
.bg-common {
  background-color: var(--common-rarity-color);
}

.bg-uncommon {
  background-color: var(--uncommmon-rarity-color);
}

.bg-rare {
  background-color: var(--rare-rarity-color);
}

.bg-epic {
  background-color: var(--epic-rarity-color);
}

.bg-legendary {
  background-color: var(--legendary-rarity-color);
}

.bg-brick {
  background-color: var(--primary-brick-color);
}

/* Primary Button */

.btn-primary, .btn-check:focus+.btn-primary, .btn-primary:focus {
  background-color: var(--primary-brick-color);
  border-color: var(--primary-brick-color);
  box-shadow: 0 0;
}

.btn-primary:hover {
  background-color: var(--secondary-brick-color);
  border-color: var(--secondary-brick-color);
}

.btn-primary:disabled, .btn-primary.disabled {
  background-color: var(--tertiary-brick-color);
  border-color: var(--tertiary-brick-color);
}

.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  background-color: var(--primary-brick-color);
  border-color: var(--primary-brick-color);
  box-shadow: 0 0 0 0.25rem var(--primary-brick-color);
}

/* Secondary Button */

.btn-secondary, .btn-check:focus+.btn-secondary, .btn-secondary:focus {
  background-color: var(--primary-tan-color);
  border-color: var(--primary-tan-color);
  color: var(--primary-brick-color);
}

.btn-secondary:hover {
  background-color: var(--secondary-tan-color);
  border-color: var(--secondary-tan-color);
  color: var(--primary-brick-color);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  background-color: var(--tertiary-tan-color);
  border-color: var(--tertiary-tan-color);
}

.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  background-color: var(--primary-tan-color);
  border-color: var(--primary-tan-color);
  box-shadow: 0 0 0 0.25rem var(--primary-tan-color);
}

/* Info button */

.btn-info, .btn-check:focus+.btn-info, .btn-info:focus {
  background-color: rgb(0,0,0,0);
  border-color: rgb(0,0,0,0);
  color: #fff;
  box-shadow: 0 0;
}

.btn-info:hover {
  background-color: rgb(0,0,0,0);
  border-color: rgb(0,0,0,0);
  color: #fff;
}

.btn-info:disabled, .btn-info.disabled {
  background-color: rgb(0,0,0,0);
  border-color: rgb(0,0,0,0);
  color: var(--primary-gray-color);
}

.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  background-color: rgb(0,0,0,0);
  border-color: rgb(0,0,0,0);
  box-shadow: 0 0;
  color: var(--primary-brick-color);
}

.block-header {
  padding-left: 2px;
  padding-right: 2px;
}

.blur {
  box-shadow: 0 0 15px 15px var(--primary-background-color) inset;
}

.boldTitle {
  font-size: 48px;
  font-weight: 800;
}

.a-button {
  height: 36px;
  min-width: 32px;
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.currency {
  color: var(--currency-color);
}

.a-dropdown {
  max-width: 300px;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}

li {
  margin: 16px 0
}

.field {
  margin-bottom: 16px;
}

.footer {
  width: 100%;
  max-height: 180px;
  justify-items: center;
}

.footer-text {
  padding: 16px 32px 32px 32px;
  text-align: center;
  opacity: 0.3;
  font-size: small;
}

h2 {
  color: white;
}

h3 {
  color: var(--primary-tan-color);
  font-size: medium;
  font-weight: normal;
}

h4 {
  color: white;
}

html {
  height: 100%;
}

.icon {
  top: 50%;
  transform: translate(0, -25%);
}

.page-container {
  padding:16px 16px;
  box-shadow: 0px 0px 8px black;
  border-radius: 5px;
  background-color:var(--secondary-background-color);
  min-height: 100px;
}

p {
  margin: 16px 0
}

.page-container-header {
  padding:8px 8px;
}

// pagination
.page-link {
  background-color: var(--tertiary-brick-color);
  border-color: var(--tertiary-brick-color);
  color: var(--text-gray-color);

  &:hover {
    background-color: var(--secondary-brick-color);
    border-color: var(--secondary-brick-color);
    color: white;
  }

  .active>& {
    background-color: var(--primary-brick-color);
    border-color: var(--primary-brick-color);
  }
}

#root {
  height: 100%;
}

.route-page {
  padding: 72px 64px 32px 64px;
  min-height: 90vh;
  max-width: 1700px;
  margin: auto;
}

.Toastify__progress-bar {
  background: var(--primary-brick-color);
}

/* Bootstrap Large */
@media (max-width: 992px) {

  .footer {
    padding: 32px 8px 32px 8px;
  }

  .route-page {
    padding: 72px 8px 32px 8px;
  }

  .tooltip {
    display: none;
  }
  
}

/* Bootstrap Medium */
@media (max-width: 768px) {

  .a-dropdown {
    max-width: 800px;
  }
  
}

/* Bootstrap Small */
@media (max-width: 576px) {

  .block-header {
    padding-left: 8px;
    padding-right: 8px;
  }

  .route-page {
    padding: 72px 0px 32px 0px;
  }
  
}

@font-face {
  font-family: Oldenburg;
  src: url(./assets/fonts/Oldenburg-Regular.ttf);
}